<template>
    <div class="level_index level_indexI">
        <div class="search">
            <el-form :inline="true">

                <el-form-item label="APP类型" prop="">

                    <!-- <el-input clearable @keydown.enter.native="searchName()" v-model="searchtitle"
                        placeholder="请输入关键字" /> -->

                    <el-select v-model="searchtitle" clearable placeholder="请选择APP类型">
                        <el-option v-for="item in typeArray" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>

                </el-form-item>


                <el-form-item>
                    <!-- <el-button type="primary" @click.stop="searchName">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button> -->

                    <el-button type="primary" @click="toSearch" v-if="getPurviewNew('sys:app:add')">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加版本
                    </el-button>

                    <!-- <el-button class="block" @click="del">批量删除</el-button> -->
                </el-form-item>
            </el-form>
        </div>

        <!-- <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border> -->

        <el-table class="el-tableII FanfuTableBox" :data="data.list" stripe height="100%" border ref="multipleTable">

            <el-table-column show-overflow-tooltip type="selection" min-width="40" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip prop="type" label="类型" align="center" min-width="80" />

            <el-table-column show-overflow-tooltip prop="version" label="版本号" align="center" min-width="80" />

            <el-table-column show-overflow-tooltip prop="url" label="地址" align="left" min-width="400" />
            <!-- <el-table-column show-overflow-tooltip    prop="" label="地址" align="left">
                <template slot-scope="scope">
                    {{scope.row.url?GLOBAL.BASE_URL+ scope.row.url:''}}
                </template>
            </el-table-column> -->

            <el-table-column show-overflow-tooltip prop="create_time" label="上传时间" align="left" min-width="150" />


            <el-table-column show-overflow-tooltip label="操作" min-width="210" align="center"
                :width="autoTableWidth(['sys:app:download','sys:app:edit','sys:app:drop'],75)">
                <template slot-scope="scope">
                    <div class="operate">
                        <el-button @click="view(scope.row)" v-if="getPurviewNew('sys:app:download')">下载</el-button>
                        <el-button type="primary" @click="edit(scope.row)" v-if="getPurviewNew('sys:app:edit')"> 编辑
                        </el-button>
                        <el-button type="danger" @click="del(scope.row)" v-if="getPurviewNew('sys:app:drop')"> 删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>

            <!--  -->
        </el-table>
        <!-- </Fanfu-Table> -->

        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" />

        <Search ref="searchRef" v-model="search" @refresh="getList(data.size)" />


    </div>
</template>

<script>
    import Search from './components/Search'

    import resizeDetector from 'element-resize-detector'

    export default {
        components: {
            Search
        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],

                typeArray: [],

                data: {
                    list: [],
                    page: 1,
                    size: 15,
                    total: 0
                },

                searchtitle: ''
            }
        },

        watch: {

            searchtitle(newValue, oldValue) {
                // if (oldValue.length > 0 && newValue.length == 0) {
                this.searchName()
                // }
            }

        },

        mounted() {

            this.modifyLayout()

            this.$http.get('/api//appversion/type', {

            }).then((res) => {
                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                if (res.code == 200) {

                    this.typeArray = res.data

                }
                if (res.code != 200) { //请求错误
                    this.$message.error(res.msg);
                }
            }).catch((err) => {
                console.log(err)
            });

        },

        created() {
            this.modifyLayout()

            this.getList()
        },

        methods: {

            getList() {

                this.$http.get('/api/appversion', {

                    type: this.searchtitle,

                    page_size: this.data.size,
                    page: this.data.page

                }).then((res) => {

                    if (res.code == 200) {

                        this.data.list = res.data.data

                        this.data.total = res.data.total

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });

            },

            searchName() {
                this.data.page = 1
                this.getList()
            },

            view(row) {
                // console.log(row)

                const elink = document.createElement('a')
                elink.href = this.GLOBAL.BASE_URL + row.url
                elink.click()

            },
            edit(row) {
                console.log(row)

                this.$refs.searchRef.edit(row)
            },

            del(row) {
                console.log(row)

                const h = this.$createElement
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！')]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'

                            this.$http
                                .del('/api/appversion/' + row.id)
                                .then((res) => {
                                    // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)

                                    if (res.code == 200) {
                                        this.getList()
                                    }

                                    if (res.code != 200) {
                                        //请求错误
                                        this.$message.error(res.msg)
                                    }

                                    // setTimeout(() => {
                                    done()
                                    setTimeout(() => {
                                        instance.confirmButtonLoading = false
                                    }, 300)
                                    // }, 3000);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })

                        } else {
                            done()
                        }
                    }
                }).then((action) => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                })
            },

            toSearch() {
                this.$refs.searchRef.open()
            },

            modifyLayout() {
                const erd = resizeDetector()

                erd.listenTo(document.getElementsByClassName('search'), (element) => {
                    const width = element.offsetWidth
                    const height = element.offsetHeight

                    var a = 141 + 60 - 35 + height - 30
                    if ($('.level_indexI .FanfuTableBox')[0]) {
                        $('.level_indexI .FanfuTableBox')[0].setAttribute('style',
                            'height: calc(100vh - ' + a +
                            'px) !important')
                    }

                    var b = 141 + 60 + 33 + height - 30
                    if ($('.level_indexI .el-table')[0]) {
                        $('.level_indexI .el-table')[0].setAttribute('style', 'height: calc(100vh - ' +
                            b +
                            'px) !important')
                    }
                })
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`)

                this.data.page = val

                this.getList()
            }
        },

    }
</script>


<style lang="scss" scoped></style>