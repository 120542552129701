<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit?'编辑版本':'新增版本'" :visible.sync="show" width="500px" class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" label-width="80px">
            <!--  -->

            <el-form-item label="类型" prop="">
                <el-select v-model="appInfo.type" placeholder="请选择类型">
                    <el-option :label="'PDA'" :value="'PDA'"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="版本号" prop="">
                <el-input v-model="appInfo.version" />
            </el-form-item>

            <el-form-item label="上传文件" prop="" style="width: 100%">
                <el-upload drag class="SelfUploadBox" action="/api/appversion/uploadApp" :headers="Headers" name="file"
                    list-type="picture-card" :limit="1" :on-success="handleAvatarSuccess " :on-remove="handleRemove"
                    :before-upload="beforeAvatarUpload">
                    <!-- :show-file-list="true" :file-list="photoList" -->
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖拽到此处或点击上传</div>
                </el-upload>
            </el-form-item>

            <el-form-item label="地址" prop="">
                <!-- v-model="appInfo.url" -->
                <el-input disabled :value="appInfo.url?GLOBAL.BASE_URL+ appInfo.url:''" />
                <el-button type="success" size="mini" style="margin-right: 30px" @click="copy">复制</el-button>
            </el-form-item>

            <!--  -->


            <!-- <el-form-item label="状态" prop=""  width="90">
                    <el-switch
                      v-model="appInfo.status"
                      :active-value="1"
                      active-color="#5BD995"
                      active-text="正常"
                      :inactive-value="2"
                      inactive-color="#D9D9D9"
                      inactive-text="禁用"
                      class="switchSelfBox"
                    >
                    </el-switch>
                  </el-form-item> -->

        </el-form>

        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>


    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                radio: '1',

                appInfo: {
                    type: '',
                    version: '',
                    url: ''
                },

                depList: [],

                // // 回显图片的路径
                // photoList: [{
                //     url: ''
                // }],

                // 图片的路径
                dialogImageUrl: '',



                isEdit: false

            }
        },

        mounted() {},

        created() {},

        watch: {

            dialogImageUrl(value) {
                this.appInfo.url = value
            },

            show(value) {
                if (this.show) {


                } else {

                    this.isEdit = false

                    this.appInfo = {
                        type: '',
                        version: '',
                        url: ''
                    }
                    this.dialogImageUrl = ""

                }
            },

        },

        computed: {
            Headers() {
                let token = localStorage.getItem('token')
                return {
                    token
                }
            }
        },

        methods: {
            copy() {
                /* 复制内容到文本域 */
                navigator.clipboard.writeText(this.GLOBAL.BASE_URL + this.appInfo.url)
            },

            open() {
                this.formShow = true
                this.show = true
            },

            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

            edit(row) {

                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', row)

                this.appInfo = {
                    ...row
                }


                this.isEdit = true

                this.open()


            },

            save() {
                console.log(this.appInfo)

                if (this.isEdit) {

                    this.$http
                        .put('/api/appversion/' + this.appInfo.id, this.appInfo)
                        .then((res) => {
                            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)
                            if (res.code != 200) {
                                //请求错误
                                this.$message.error(res.msg)
                            }
                            if (res.code == 200) {
                                this.refresh()
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })


                } else {


                    this.$http
                        .post('/api/appversion', this.appInfo)
                        .then((res) => {
                            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)
                            if (res.code != 200) {
                                //请求错误
                                this.$message.error(res.msg)
                            }
                            if (res.code == 200) {
                                this.refresh()
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                }

            },

            handleRemove(file, fileList) {
                console.log(file, fileList)
                this.dialogImageUrl = ''
            },
            handleAvatarSuccess(res, file) {
                this.dialogImageUrl = res.data.filePath
                console.log(this.dialogImageUrl)
            },
            beforeAvatarUpload(file) {
                // const isLt2M = file.size / 1024 / 1024 < 2
                // if (!isLt2M) {
                //   this.$message.error('上传头像图片大小不能超过 2MB!')
                // }
                // return /* isJPG && */ isLt2M


                return true
            }

            //
        }
    }
</script>

<style>
    /* 	.selfInputBoxIV .el-input__inner[placeholder="请选择所属卫健委"] {
		border: 1px red solid !important;
		box-sizing: content-box !important;
	} */

    .selfInputBoxIV .el-input__suffix {
        /*    border: 1px red solid !important;
    box-sizing: content-box !important; */
        right: -10px;
    }
</style>

<style scoped lang="scss">
    .trisection {
        .el-select {
            /deep/ .el-input__inner {
                // border: 1px red solid !important;
                box-sizing: content-box !important;
            }
        }
    }
</style>